export const realtorData = {
  email: 'caysa@unne.cl',
  phone:'56951123935'
  // email: 'contacto@accionpanal.com',
};

export const footerData = {
  email: 'caysa@unne.cl',
  phone:'951123935',
  wsp:'951123935',
}
